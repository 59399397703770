.top {
  padding: 0 5%;
  height: 80px;
  line-height: 80px;
  background-color: #f38779;
}

.img {
  width: 100%;
}

.method {
  padding: 10px 50px;
  height: 140px;
  background-color: rgba(241, 241, 241, 0.87);
}

.input {
  margin-bottom: 20px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.btn {
  width: 50%;
  height: 40px;
  color: #fff;
  font-size: 18px;
  background-color: rgb(88, 177, 228);
  border-radius: 5px;
}

.foot {
  position: fixed;
  bottom: 0;
  height: 38px;
  line-height: 38px;
  color: #fff;
  background-color: #030303;
}

.tit {
  height: 34px;
  color: #be4242;
  border-bottom: 2px solid #be4242;
}

.left {
  margin-left: 9%;
  height: 240px;
  /* border: 1px solid #ddd; */
}

.address {
  width: 230px;
}

.count {
  padding: 1px 8px;
  border-radius: 20px;
  background-color: #be4242;
}